// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"

const initialSelected = () => {
  const item = window.sessionStorage.getItem("selectableRows")
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : []
}

export const selectedSlice = createSlice({
  name: "selectedjj",
  initialState: {
    selectedjj: initialSelected(),
  },
  reducers: {
    handleSelectableRows: (state, action) => {
      state.selectedjj = action.payload
      window.sessionStorage.setItem("selectableRows", JSON.stringify(action.payload))
    },
  }
})

export const {
  handleSelectableRows,
} = selectedSlice.actions

export default selectedSlice.reducer
